export const ListSocialsAdmin = `query MyQuery($Limit: Int, $Filter: String, $Page: Int, $Search: String) {
    listSocialPostAdmin(input: {Limit: $Limit, Filter: $Filter, Page: $Page, Search: $Search}) {
        Total
        Published
        Pending
        Trash
        Items {
            SocialPostID
            UserID
            CognitoUserID
            Title
            Comment
            Status
            CreatedAt
            UpdatedAt
            PublishedAt
            Deleted
            File {
                bucket
                region
                key
                order
            }
        }
    }
}`

export const MoveSocialToTrash = `mutation MyMutation($SocialPostID: Int) {
    deleteSocialPostAdmin(SocialPostID: $SocialPostID) {
        Message
        Status
    }
}`

export const GetSocialPostAdmin = `query MyQuery($SocialPostID: Int!) {
    getSocialPostAdmin(SocialPostID: $SocialPostID) {
        SocialPostID
        UserID
        CognitoUserID
        Title
        Comment
        Status
        CreatedAt
        UpdatedAt
        Deleted
        PublishedAt
        File {
            bucket
            region
            key
            order
        }
    }
}`

export const UpdateSocial = `mutation MyMutation($SocialPostID: Int!, $Comment: String, $File: S3Object, $PublishedAt: String, $Status: Int, $Title: String) {
    updateSocialPostAdmin(SocialPostID: $SocialPostID, input: {Comment: $Comment, File: $File, PublishedAt: $PublishedAt, Status: $Status, Title: $Title}) {
        Message
        Status
    }
}`

export const CreateSocial = `mutation MyMutation($Comment: String, $File: S3Object, $PublishedAt: String, $Status: Int, $Title: String) {
    createSocialPostAdmin(input: {Comment: $Comment, File: $File, PublishedAt: $PublishedAt, Status: $Status, Title: $Title}) {
        Message
        Status
    }
}`