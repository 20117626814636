import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, Button, Paper, Tabs, Tab, CircularProgress, InputAdornment } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate } from '../../components/button/button-template';
import MessageDialog from '../../components/dialog/message-dialog';
import { ListSocialsAdmin } from '../../lib/graphql-command/socials-command';
import moment from 'moment';
import { DeletePostDialog } from '../../components/socials/delete-post-dialog';
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(theme => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        height: '80px',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
    },
    search: {
        flex: 'auto'
    },
    videoTitle: {
        width: '40%'
    },
    image: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    },
    avatar: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    username: {
        verticalAlign: 'top',
        width: '50%'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    }
}))

const SocialPost = () => {
    const classes = useStyle();
    const [posts, setPosts] = useState();
    const [deletePost, setDeletePost] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: '', FilterDate: null});
    const [filter, setFilter] = useState('all');
    const [page, setPage] = useState(1)
    const searchRef = useRef();
    const pageSize = 20

    const filters = [
        {
            key: 'all',
            label: 'All'
        },{
            key: 'published',
            label: 'Published'
        }, {
            key: 'pending',
            label: 'Pending'
        }
    ]

    useEffect(() => {
        // navigate(`/social-post?page=${page}&`)
        getListPost();
    }, [page, search, filter])

    const getListPost = () => {
        setLoading(true);
        API.graphql(graphqlOperation(ListSocialsAdmin, {
            Limit: pageSize,
            Page: page,
            Search: search.FilterText,
            Filter: filter
        }))
        .then(result => {
            setPosts(result.data.listSocialPostAdmin)
            setLoading(false)
        })
        .catch(err => console.log(err))
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
            FilterText: searchRef.current.value || ''
        })
    }

    const handleDelete = (e, post) => {
        e.preventDefault();
        setDeletePost(post)
        setOpenDeleteDialog(true)
    }

    const displayDate = post => {
        const prefix = post?.Status == 1 && post?.PublishedAt ? `Published` : 'Last Modified'
        return prefix + '\n' + moment(post?.Status === 1 && post?.PublishedAt ? post?.PublishedAt : (post?.UpdatedAt || post?.CreatedAt)).format('YYYY/MM/DD [at] hh:mm a')
    }

    const pageCount = () => {
        return Math.ceil((total()||0) / pageSize)
    }

    const handleChangeTab = (event, newValue) => {
        setPage(1)
        setFilter(filters[newValue]?.key || 0)
    }

    const total = () => {
        return filter === 'all' ? posts?.Total : filter === 'published' ? posts?.Published : posts?.Pending
    }

    const currentFilterIndex = () => {
        return filters.findIndex(tab => tab.key === filter)
    }
    console.log(posts)
    console.log(pageCount())

    return (
        <Box>
            <Typography variant='h4'>Socials
                <ButtonTemplate
                    borderradius='50px'
                    variant='outlined'
                    color='primary'
                    size='small'
                    margin='0 0 5px 10px'
                    onClick={() => navigate('/new-social')}
                >
                    Add New
                </ButtonTemplate>
            </Typography>
            <Box py={10}>
                <Grid container>
                    <Grid item>
                        <Paper>
                            <Tabs
                                value={currentFilterIndex()}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                size='small'
                            >
                                <Tab label={`All (${posts?.Total || 0})`} />
                                <Tab label={`Published (${posts?.Published || 0})`} />
                                <Tab label={`Pending (${posts?.Pending || 0})`} />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Grid container justify='flex-end' spacing={10}>
                            <Grid item>
                                <Box>
                                    <TextField variant='outlined'
                                        size='small'
                                        inputRef={searchRef}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleSearch();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.textIcon}
                                                    onClick={e => {
                                                        searchRef.current.value = ''
                                                        handleSearch()
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ButtonTemplate variant='contained'
                                        color='primary'
                                        borderradius='50px'
                                        margin='0 0 0 10px'
                                        onClick={handleSearch}
                                    >Search Socials</ButtonTemplate>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {posts?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {posts?.Items?.map(post => (
                                <TableRow key={post.SocialPostID} className={classes.row}>
                                    <TableCell className={classes.username}>
                                        <Link to={`/social-post/${post.SocialPostID}`} className={classes.link}>{post.Title}</Link>
                                        <i>{post.Status === 0 ? ' - Pending' : post.Status === 2 ? ' - Draft' : ''}</i>
                                        <Box className={classes.links}>
                                            <Link to={`/social-post/${post.SocialPostID}`}>Edit</Link>
                                            <Link to='#' className={classes.trash} onClick={e => handleDelete(e, post)}>Trash</Link>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {displayDate(post)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>

            {posts?.Items?.length <= 0 && <Box>
                No posts found
            </Box>}

            {total() > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {total()} posts
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                            <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <DeletePostDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, post: deletePost, getListPost}} />
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default SocialPost;